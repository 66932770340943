html{
  overflow-x: hidden;
}

.App {
  font-family: "Courier Prime";
  text-align: center;
  width: 100vw !important;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

div.App-header {
  background-color: #ffffff00;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: black;
  font-size: 28px;
  width: 100%;
  text-decoration: underline;
  text-transform: uppercase;
  letter-spacing: 4px;
  position: fixed;
  top: 0;
}

div.zoomButtons{
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

div.zoomButtons, div.zoomButtons span{
  text-decoration: none !important;
}

div.zoomButtons span{
  width: 20px;
}

div.zoomButtons span:hover svg{
  stroke: red;
  fill:  red;
  cursor:  pointer;
}

input{
  font-family: "Courier Prime";
  border-color: #00000000;
}

div.header-component{
  width: 100%;
  align-items: center;
  margin: auto auto;
}

div.header-component div.header-title-artist{
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

div.header-title, div.header-title input{
  font-size: 40px;
  font-weight: 700;
}

div.header-artist{
/*  padding: 0px 6px;
*/}

div.header-artist input, div.header-artist div.header-artist-by{
  padding: 1px 2px;
}

div.header-artist input{
  font-size: 20px;
}

div.header-artist div.header-artist-by{
  font-size: 16px;
  height: 100%;
}

input::placeholder{
  opacity: 0.5;
}

div.lineComponent{
  border: #fff 1px solid;
}

div.lineComponentHover:hover{
  border: #efefef 1px solid;
}

div.svgHover:hover{
  background-color: #000;
}

div.svgHover:hover div svg{
  stroke: blue !important;
  fill: blue !important;
}

div.svgHover:hover div svg{
  stroke: #fff !important;
  fill: #fff !important;
}

div.lineInput{
      text-rendering: auto;
      letter-spacing: normal;
      word-spacing: normal;
      line-height: normal;
      text-transform: none;
      text-indent: 0px;
      text-shadow: none;
      display: inline-block;
      text-align: start;
      appearance: auto;
      -webkit-rtl-ordering: logical;
      cursor: text;
      margin: 0em;
      padding: 1px 2px;
/*      border-image: initial;
*/      margin-bottom: 1px;
}

input{
  background-color: #00000000 !important;
}

/*div.lockButton{
  border: 1px solid #000;
  padding: 4px 6px;
  cursor:  pointer;
}

div.lockButton:hover{
  text-decoration: underline;
  background-color: blue !important;
  color:  white !important;
}
*/

div.lockButton{
  padding: 4px 6px;
  cursor:  pointer;
  width: 40px;
  font-size: 40px;
}

div.lockButton i{
  font-size: 30px;
  color:  #999;
}

div.lockButton:hover .fa-lock, div.lockButton:hover .fa-unlock{
  color:  black;
}

span.isChord{
  padding:  2px 4px;
}

span.isChord:first-child{
  margin-left:  -4px;
}

span.isSpace{
  margin: 0px -4px;
}

span.isChord:hover{
  background: #eee;
  text-decoration: underline;
  cursor: pointer;
}

span.selectedChord{
  background-color: black;
  color: white;
}

span.isChord.selectedChord:hover{
  background-color: black;
  color: red;
}

div.mainMenuButton{
  background-color: black;
  font-size: 16px;
  height: 33%;
  cursor: pointer;
  border-bottom: white solid 1px;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

div.mainMenuButton:hover{
  background-color: blue;

}
